import React from 'react';

const Clock = ({ className }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M13.919 7A6.919 6.919 0 1 0 .08 7 6.919 6.919 0 0 0 13.92 7zM6.593.895h.814v1.628h-.814V.895zm-4.07 6.512H.895v-.814h1.628v.814zm4.884 5.698h-.814v-1.628h.814v1.628zm0-6.105a.407.407 0 0 1-.814 0L2.669 4.734l.203-.352 3.926 2.267A.403.403 0 0 1 7 6.593c.074 0 .142.021.202.056l3.573-2.064.204.353L7.407 7zm4.07-.407h1.628v.814h-1.628v-.814z" />
  </svg>
);

export default Clock;
