import React, { ReactNode, createContext, useState } from 'react';
import { useRoles } from '../../queries/roles';
import { withErrorHandler } from '../ErrorContext';

export type RolesContextType = {
  roles: any;
  isLoading: boolean;
  roleSwitchModalActive: boolean;
  setRoleSwitchModalActive: any;
  setMatchUuid: any;
  matchUuid: any;
};

const defaultState = {
  roles: [],
  isLoading: false,
  roleSwitchModalActive: false,
  setRoleSwitchModalActive: () => undefined,
  setMatchUuid: () => undefined,
  matchUuid: null,
};

export const RolesContext = createContext<RolesContextType>(defaultState);

export const RolesContextProvider = (props: {
  children: ReactNode;
  showGenericError: (err: any) => void;
}) => {
  const [matchUuid, setMatchUuid] = useState(null);
  const [roleSwitchModalActive, setRoleSwitchModalActive] = useState(false);

  const {
    isLoading: isLoadingRoles,
    data: roles,
    error,
  } = useRoles(
    {
      matchId: matchUuid,
    },
    {
      enabled: Boolean(matchUuid),
    }
  );

  if (error) {
    props.showGenericError(error);
  }

  const isLoading = isLoadingRoles || roles === undefined;

  return (
    <RolesContext.Provider
      {...props}
      value={{
        isLoading,
        roles,
        roleSwitchModalActive,
        setRoleSwitchModalActive,
        setMatchUuid,
        matchUuid,
      }}
    />
  );
};

export default withErrorHandler(RolesContextProvider);
