import React from 'react';

const Cross = ({ className }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.808 16l-5.972-5.971L2.866 16l-2.03-2.029L6.808 8 .836 2.029 2.866 0l5.97 5.971L14.808 0l2.028 2.029L10.866 8l5.97 5.971z"
      fillRule="nonzero"
    />
  </svg>
);

export default Cross;
