import React from 'react';

const Trash = ({ className }) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M1.49 18.28a1.45 1.45 0 0 0 1.469 1.446h9.885a1.45 1.45 0 0 0 1.468-1.447l.57-11.923H.942L1.49 18.28zm9.03-9.535c0-.416.242-.745.658-.745.417 0 .658.329.658.745v8.241c0 .417-.241.746-.658.746-.416 0-.657-.33-.657-.746v-8.24zm-3.287 0c0-.416.241-.745.657-.745.417 0 .658.329.658.745v8.241c0 .417-.241.746-.658.746-.416 0-.657-.33-.657-.746v-8.24zm-3.288 0c0-.416.241-.745.658-.745.416 0 .657.329.657.745v8.241c0 .417-.24.746-.657.746-.417 0-.658-.33-.658-.746v-8.24zM15.145 2.63h-3.967V.68c0-.395-.197-.68-.614-.68H5.26c-.416 0-.657.285-.657.68v1.95H.679c-.416 0-.679.307-.679.723v.833c0 .417.263.855.68.855h14.487c.417 0 .636-.46.636-.855v-.833c-.022-.416-.241-.723-.658-.723zm-5.282 0H5.918V1.315h3.945V2.63z" />
  </svg>
);

export default Trash;
