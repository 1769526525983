import React from 'react';
import moment from 'moment';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { styled } from 'styled-components';
import { withMatch } from '../../contexts/MatchContext';
import { withUser } from '../../contexts/UserContext';
import { refereeApprovals } from '../../services/approval';
import { coachCanEnterScore, hasComments } from '../../services/dwfOptions';
import Calendar from '../atoms/Icons/Calendar';
import Clock from '../atoms/Icons/Clock';
import Comment from '../atoms/Icons/Comment';
import Cross from '../atoms/Icons/Cross';
import Whistle from '../atoms/Icons/Whistle';
import { Meta } from '../atoms/Meta/Meta';
import { RefereeApproved } from '../atoms/RefereeApproved';
import IconLink from '../molecules/IconLink';
import Comments from '../organisms/Comments';
import { theme } from '../templates/ui';
import TooltipItem from './TooltipItem';

const MetaWrapper = styled.div`
  position: relative;
  display: flex;
  padding: ${theme.sizing.large} ${theme.sizing.xlarge} ${theme.sizing.large} ${theme.sizing.xlarge};
  background-color: ${theme.color.gray.dark};
  line-height: ${theme.sizing.xlarge};
  border-top: 1px solid ${theme.color.gray.light};
  flex-wrap: wrap;
`;

const StyledMeta = styled(Meta)`
  margin-right: ${theme.sizing.large};
`;

const StyledCalendar = styled(Calendar)`
  fill: ${theme.color.primary.base};
  height: 1rem;
`;

const StyledClock = styled(Clock)`
  fill: ${theme.color.primary.base};
  height: 1rem;
`;

const StyledCross = styled(Cross)`
  fill: ${theme.color.error.base};
  margin-left: ${(props) => (props.alignRight ? 'auto' : 'unset')};
`;

const StyledWhistle = styled(Whistle)`
  fill: ${theme.color.primary.base};
  height: 1rem;
`;

const StyledComment = styled(Comment)`
  fill: ${theme.color.primary.base};
  height: 1rem;
`;

const Spacer = styled.div`
  margin-right: ${theme.sizing.large};
  display: inherit;
`;

const StyledMetaRefereeCode = styled(Meta)`
  font-weight: 600;
  margin-right: ${theme.sizing.large};
`;

const StyledButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  outline: none;
  cursor: pointer;
  padding: 0;

  &:disabled {
    color: ${theme.color.gray.darker};

    svg {
      fill: ${theme.color.gray.darker};
    }
  }

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

const StyledIconLink = styled(IconLink)`
  svg {
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    height: 11px;
    margin-left: 3px;
  }
`;

const getMoment = (date) => moment(date, 'YYYY-MM-DD HH:mm');

const MatchMeta = ({
  user,
  date,
  matchCode,
  matchId,
  commentsCount,
  className,
  cancellation,
  matchUuid,
  matchGame,
  approvals,
  currentTeamId,
  canConfirmMatch,
  compact = false,
  hideRefereeCode = false,
}) => {
  const matchDetailRoute = useRouteMatch('/matches/:id');
  const matchCommentsRoute = useRouteMatch('/matches/:id/comments');
  const history = useHistory();
  const getReferees = () => {
    return (
      <>
        {refereeApprovals(approvals).length >= 1 && <RefereeApproved refereeId={'S1'} />}
        {refereeApprovals(approvals).length >= 2 && <RefereeApproved refereeId={'S2'} />}
        {matchGame.superuser_confirmation && <RefereeApproved refereeId={'CL'} />}
      </>
    );
  };

  return (
    <>
      <MetaWrapper className={className} data-testid="metawrapper">
        {cancellation && cancellation.length > 0 && (
          <Spacer>
            <Meta
              icon={<StyledCross />}
              value={cancellation[0].minute > 0 ? 'Gestaakt' : 'Afgelast'}
              cancellation
            />
            <TooltipItem
              containerId="cancellation-tooltip"
              tooltipTitle=""
              tooltipBody={cancellation[0].reason}
              data-testid={`cancellation-tooltip-${cancellation[0].id}`}
            />
          </Spacer>
        )}
        <StyledMeta icon={<StyledCalendar />} value={getMoment(date).format('DD-MM-YYYY')} />
        <StyledMeta icon={<StyledClock />} value={getMoment(date).format('HH:mm')} />
        {!compact && !hideRefereeCode && user && !coachCanEnterScore(matchGame) && (
          <StyledMetaRefereeCode icon={<StyledWhistle />} value={matchCode}>
            <TooltipItem
              containerId="refereecode-tooltip"
              tooltipTitle="Scheidsrechter verificatiecode"
              tooltipBody="Geef deze code aan de scheidsrechter om de wedstrijd te kunnen fluiten."
            />
          </StyledMetaRefereeCode>
        )}

        {!compact && user && hasComments(matchGame.dwf_options) && (
          <StyledButton
            data-testid="open-comments-button"
            onClick={() => history.push(`${matchDetailRoute?.url}/comments`)}
          >
            <StyledMeta icon={<StyledComment />} value={commentsCount} />
          </StyledButton>
        )}

        {!compact && getReferees()}

        {!compact && matchId && (
          <StyledIconLink to={`/matches/${matchUuid}`}>Openen</StyledIconLink>
        )}
      </MetaWrapper>

      {!compact && hasComments(matchGame.dwf_options) && (
        <Route path={`${matchDetailRoute?.url}/comments`}>
          {() => {
            return (
              <>
                <Comments
                  user={user}
                  containerOpen={matchCommentsRoute}
                  containerClose={() => {
                    history.push(`${matchDetailRoute?.url}`);
                  }}
                  currentTeamId={currentTeamId}
                  canConfirmMatch={canConfirmMatch}
                />
              </>
            );
          }}
        </Route>
      )}
    </>
  );
};

export default withUser(withMatch(MatchMeta));
