import { useCallback, useContext, useMemo, useState } from 'react';
import { produce } from 'immer';
import { getMatchConfirmationEndpoint } from '../api/matches';
import { ERROR_CODE_MATCH_ALREADY_CONFIRMED_BY_ROLE } from '../config/errorCodes';
import MatchContext from '../contexts/MatchContext';
import { RoleContext } from '../contexts/RoleContext/RoleContext';
import UserContext from '../contexts/UserContext';
import { useHasTeam } from '../hooks/useHasTeam';
import { useMatch } from '../hooks/useMatch';
import { coachCanEnterScore, getPersonType } from './user';

export const HomeCoachType = 'home_coach';
export const AwayCoachType = 'away_coach';
export const RefereeType = 'referee';
export const SuperuserType = 'superuser';

export const MatchConfirmationProvider = ({ children }) => {
  const { match, updateMatch } = useContext(MatchContext);
  const { role, permissions, team_id } = useContext(RoleContext);
  const user = useContext(UserContext);
  const { showShootoutsAreRequiredNotification } = useMatch();
  const { isCoachOfTeam } = useHasTeam();
  const [loading, setLoading] = useState(false);

  const handleMatchConfirmation = useCallback(
    (personType) => {
      let column = '';

      switch (personType) {
        case HomeCoachType:
          column = 'home_team_approval';
          break;
        case AwayCoachType:
          column = 'away_team_approval';
          break;
        case RefereeType:
          column = 'referee_confirmation';
          break;
        case SuperuserType:
          column = 'superuser_confirmation';
          break;
        default:
          return;
      }

      const matchGame = produce(match, (draft) => {
        const newApprovals = draft.match_approvals;

        newApprovals.push({
          by_referee: personType === RefereeType,
          person: {
            id: user.id,
          },
        });

        draft[column] = true;
        draft.match_approvals = newApprovals;
      });
      updateMatch(matchGame, null, 'match-detail');

      return matchGame;
    },
    [match, user, updateMatch]
  );

  const addMatchConfirmation = useCallback(
    async (currentTeamId) => {
      if (!match) return;

      setLoading(true);

      let personType;

      if (currentTeamId === match.home_team.id && isCoachOfTeam(match.home_team.id)) {
        personType = HomeCoachType;
      } else if (currentTeamId === match.away_team.id && isCoachOfTeam(match.away_team.id)) {
        personType = AwayCoachType;
      } else {
        personType = getPersonType(user, match, null, {
          role,
          permissions,
          team_id,
        });
      }

      if (personType === RefereeType || coachCanEnterScore(user, match)) {
        if (showShootoutsAreRequiredNotification) {
          window.alert(
            'Deze wedstrijd is in een gelijkspel geëindigd. Om de wedstrijd te kunnen afronden moet de shoot-out score nog worden ingevuld.'
          );
          setLoading(false);

          return;
        }
      }

      const confirmationMethod = getMatchConfirmationEndpoint(personType);

      try {
        const { data } = await confirmationMethod({
          matchUuid: match.uuid,
          personType,
          role,
          teamId: team_id ?? null,
          pageKey: 'match-detail',
        });
        const updatedMatch = handleMatchConfirmation(personType);
        setLoading(false);

        return {
          approvalId: data.updateMatch.approval_id,
          match: updatedMatch,
        };
      } catch (e) {
        if (e.response?.data.code === ERROR_CODE_MATCH_ALREADY_CONFIRMED_BY_ROLE) {
          alert('De wedstrijd is al afgerond door deze rol.');

          window.location.reload();

          return;
        }

        if (e.response) {
          alert('De wedstrijd kon niet bevestigd worden, probeer het later nogmaals.');
        }
      }
      setLoading(false);
    },
    [match, user, handleMatchConfirmation]
  );

  return children(
    useMemo(() => {
      return {
        addMatchConfirmation,
        matchConfirmationLoading: loading,
      };
    }, [addMatchConfirmation, loading])
  );
};
