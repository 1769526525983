import React from 'react';
import { theme } from '../../templates/ui';

const Link = ({ className }) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs>
      <path id="a" d="M0 0h375v929H0z" />
      <path id="d" d="M0 0h385v289H0z" />
      <filter
        x="-1.9%"
        y="-2.6%"
        width="103.9%"
        height="105.2%"
        filterUnits="objectBoundingBox"
        id="c"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g transform="translate(-21 -283)" fill="none" fillRule="evenodd">
      <mask id="b" fill={theme.color.gray.white}>
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <g transform="translate(-10 60)">
          <use fill={theme.color.gray.black} filter="url(#c)" xlinkHref="#d" />
          <use fill={theme.color.gray.white} xlinkHref="#d" />
        </g>
        <g transform="translate(10 195)" fill={theme.color.primary.base} fillRule="nonzero">
          <rect
            strokeOpacity=".6"
            stroke={theme.color.primary.base}
            fillOpacity=".1"
            x=".5"
            y=".5"
            width="354"
            height="142"
            rx="3"
          />
          <path d="M20.358 98.911h-9.27v-9.27h5.161v1.554h-3.624v6.18h6.18v-3.608h1.553v5.144zm1.553-6.714h-1.553v-1.453l-4.092 4.075-1.085-1.085 4.091-4.092H17.82v-1.553h4.092v4.108z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Link;
