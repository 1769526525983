import React from 'react';
import { theme } from '../../templates/ui';

const SubstituteIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    className={className}
  >
    <path
      fill={theme.color.primary.base}
      d="M0 9.4L3.4 12l1.2-1.3L.8 8zM19.4 10.7l1.2 1.3L24 9.4 23.2 8zM12 2.6c1.9 0 3.4-1.6 3.4-2.6H8.6c0 .9 1.5 2.6 3.4 2.6z"
    />
    <path
      fill={theme.color.primary.base}
      d="M7.9 21.4c-.8-1.2-1.2-2.6-1.2-4.1-.1-4 3.3-7.3 7.3-7.3 1.5 0 2.8.4 4 1.2V9l.9 1 3.9-2.8-3.1-5.5L16.2.3C16 1.8 14.1 3.4 12 3.4S8 1.8 7.8.3L4.3 1.7 1.2 7.2 5.1 10 6 9v12.4h1.9z"
    />
    <circle cx="14" cy="17.4" r="6.5" fill={theme.color.gray.alt} />
    <path
      d="M14.9 21.5c.4-.2.6-.6.4-.7-.1-.1-.3.1-.4.1-.3 0-.4 0-.5-.1-.1-.1-.2-.2-.2-.5v-.4c0-.2.1-.3.1-.5l.5-1.7c0-.2.1-.3.1-.5v-.4c0-.4-.1-.7-.4-.9-.3-.2-.6-.3-1.1-.3-.3 0-.5 0-.8.1-.6.2-.4.7-.3.7.3 0 .4 0 .5.1.1.1.1.2.1.5v.4c0 .1-.1.3-.1.5l-.5 1.7c0 .2-.1.3-.1.5v.4c0 .4.1.6.4.9.3.2.7.4 1.1.4.4 0 .9-.2 1.2-.3zm.5-7.1c.2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8 0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3z"
      fill={theme.color.gray.darkest}
    />
  </svg>
);

export default SubstituteIcon;
