import React from 'react';

const Goal = ({ className }) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fillRule="nonzero">
      <path d="M4.433.933h1v1h-1zM5.833 2.333h1v1h-1zM4.433 2.333h1v1h-1zM4.433 3.733h1v1h-1zM4.433 5.133h1v1h-1zM3.033 5.133h1v1h-1zM1.633.933h1v1h-1zM3.033 3.733h1v1h-1zM1.633 3.733h1v1h-1zM1.633 2.333h1v1h-1zM5.833.933h1v1h-1zM3.033.933h1v1h-1zM3.033 2.333h1v1h-1zM1.633 5.133h1v1h-1zM1.633 6.55h10.73v1.5H1.633zM7.233 5.133h1v1h-1zM5.833 3.733h1v1h-1zM10.033 3.733h1v1h-1zM10.033 2.333h1v1h-1zM11.433 5.133h1v1h-1zM10.033.933h1v1h-1zM11.433 3.733h1v1h-1zM11.433.933h1v1h-1z" />
      <path d="M12.6 0H1.4C1.013 0 .7.338.7.755v7.043c0 .14.104.252.233.252.13 0 .234-.113.234-.252V.755c0-.14.104-.252.233-.252h11.2c.129 0 .233.113.233.252v7.043c0 .14.105.252.234.252s.233-.113.233-.252V.755c0-.417-.313-.755-.7-.755z" />
      <path d="M11.433 2.333h1v1h-1zM10.033 5.133h1v1h-1zM7.233 3.733h1v1h-1zM7.233.933h1v1h-1zM7.233 2.333h1v1h-1zM8.633 2.333h1v1h-1zM8.633 3.733h1v1h-1zM8.633 5.133h1v1h-1zM8.633.933h1v1h-1zM5.833 5.133h1v1h-1z" />
      <circle cx="7.05" cy="10.25" r="1" />
    </g>
  </svg>
);

export default Goal;
