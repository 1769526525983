import React from 'react';
import { theme } from '../../templates/ui';

const TooltipIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="none">
      <ellipse fill={theme.color.gray.alt} cx="5.701" cy="5.7" rx="5.701" ry="5.7" />
      <path
        d="M6.756 3.33A.723.723 0 0 0 7 2.78a.728.728 0 0 0-.244-.55A.826.826 0 0 0 6.17 2a.834.834 0 0 0-.588.23.729.729 0 0 0-.245.55c0 .215.083.399.245.55.163.15.36.227.588.227a.83.83 0 0 0 .586-.227zm-.374 5.052c.293-.137.448-.395.296-.513-.088-.068-.203.044-.287.044-.18 0-.307-.03-.38-.089-.073-.06-.109-.17-.109-.334 0-.065.01-.161.033-.288.022-.127.048-.241.076-.34l.349-1.234c.032-.113.057-.237.07-.372a3.88 3.88 0 0 0 .018-.285.811.811 0 0 0-.273-.634c-.181-.162-.44-.244-.777-.244a1.8 1.8 0 0 0-.594.1c-.408.143-.317.498-.227.498.184 0 .308.032.373.094s.099.172.099.33c0 .087-.012.185-.033.29a5.58 5.58 0 0 1-.078.338l-.35 1.237c-.03.13-.053.247-.067.35a2.26 2.26 0 0 0-.021.304c0 .254.093.464.281.63.188.165.476.32.814.32.22 0 .533-.082.787-.202z"
        fill={theme.color.gray.darkest}
      />
    </g>
  </svg>
);

export default TooltipIcon;
