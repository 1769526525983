import React from 'react';
import { theme } from '../../templates/ui';

const Arrow = ({ className }) => (
  <svg
    width="13"
    height="20"
    viewBox="0 0 13 20"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.324.477v12.236H6.7V9.59L13 14.593 6.701 19.74v-3.11H0V.476z"
      fill={theme.color.gray.darker}
    />
  </svg>
);

export default Arrow;
