import React from 'react';

const Shirt = ({ className }) => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M0 10.214L3.714 13l1.25-1.456L.88 8.628zM21.038 11.544L22.286 13 26 10.214l-.88-1.586z" />
    <path d="M21.357 1.857L17.588.35c-.274 1.57-2.3 3.366-4.588 3.366-2.287 0-4.316-1.798-4.588-3.366l-3.77 1.507-3.308 5.954 4.236 3.025.93-1.086v13.464h13V9.75l.931 1.086 4.235-3.025-3.309-5.954z" />
    <path d="M13 2.788c2.051 0 3.714-1.76 3.714-2.786V0H9.287l-.001.002c0 1.026 1.663 2.786 3.714 2.786z" />
  </svg>
);

export default Shirt;
