export const getGivenCardsForPlayer = (clubMemberId, match, color) => {
  return match.match_cards.filter((card) => {
    return card.club_member.id === clubMemberId && (color ? card.color === color : true);
  });
};

export const getCardsForTeamId = (teamId, cards) => {
  return cards.filter((card) => card.team.id === teamId);
};

export const sortCards = (cards) =>
  cards.sort(({ color: colorA }, { color: colorB }) => {
    if (colorA === colorB) return 0;

    if (['yellow', 'red'].includes(colorA) && colorB === 'green') return 1;

    if (colorA === 'green' && colorB === 'yellow') return -1;
    if (colorA === 'red' && colorB === 'yellow') return 1;

    return -1;
  });
