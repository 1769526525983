import React from 'react';

const ChevronLeft = ({ className }) => (
  <svg
    width="12"
    height="21"
    viewBox="0 0 12 21"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11.82 1.955a.275.275 0 0 1 0 .386L3.97 10.17a.274.274 0 0 0 0 .387l7.849 7.848a.271.271 0 0 1-.001.384l-1.572 1.557a.275.275 0 0 1-.386 0L.08 10.546a.272.272 0 0 1 0-.386L9.869.39a.275.275 0 0 1 .386 0l1.565 1.566z" />
  </svg>
);

export default ChevronLeft;
