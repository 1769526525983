import { type AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { JWTResponse, SSOConfiguration } from '../@types/Sso';
import ssoConfig from '../config/sso';
import { getPerson, mergeUserAndPerson } from '../services/auth';
import { setAuthRedirectUrl } from '../services/localStorage';
import { Location } from '../services/location';
import {
  clearPKCEData,
  getPKCECodeChallenge,
  getPKCECodeVerifier,
  getPKCENonce,
  getPKCEState,
} from '../services/pkce';
import { getSSOConfiguration, incrementSSORetries } from '../services/sso';
import Storage from '../services/storage';
import Client from './Client';

export const SSO_CONFIGURATION_KEY = 'SSO_CONFIGURATION';
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const ID_TOKEN_KEY = 'ID_TOKEN';

const { sso_client_id, sso_configuration_endpoint, sso_endpoint, sso_scope } = ssoConfig;

const getCallBackUrl = () => {
  const query = queryString.parse(window.location.search);
  const federation = query.federation instanceof Array ? query.federation[0] : query.federation;

  return `${window.location.protocol}//${window.location.host}/callback${
    federation ? `&federation=${federation}` : ``
  }`;
};

export const getMatchRoles = (
  federationReferenceId: string,
  matchId: number,
  teamId: number,
  token: string,
  pageKey: string
) =>
  Client.get<{
    id_token: string;
  }>(
    `${sso_endpoint}/openid/person/${federationReferenceId}/roles?match_id=${matchId}&team_id=${
      teamId || 0
    }`,
    {
      idToken: token,
    },
    pageKey,
    'sso.getMatchRoles'
  );

export const saveIdToken = (idToken: string, refreshToken: string) => {
  Storage.setItem(ID_TOKEN_KEY, idToken);
  Storage.setItem(REFRESH_TOKEN_KEY, refreshToken);

  return true;
};

export const logout = (url?: string) => {
  localStorage.clear();

  if (!url) {
    return window.location.reload();
  }

  window.location.replace(url);
};

export const clearIdToken = (): void => {
  localStorage.removeItem(ID_TOKEN_KEY);
};

export const getIdToken = (idToken?: string) => {
  const token = idToken || localStorage.getItem(ID_TOKEN_KEY);

  if (!!token) {
    const user = jwtDecode(token);
    const person = getPerson();

    return mergeUserAndPerson(user, person);
  }

  return {};
};

export const getRefreshToken = (): string =>
  window.localStorage.getItem(REFRESH_TOKEN_KEY) as string;

export const getAuthUrl = async (promptUserLogin = true) => {
  const { authorizationEndpoint } = await getSSOConfiguration();
  const callbackUrl = getCallBackUrl();
  const codeChallenge = getPKCECodeChallenge();
  const state = getPKCEState();
  const nonce = getPKCENonce();

  const url = `${authorizationEndpoint}\
?client_id=${sso_client_id}\
&redirect_uri=${callbackUrl}\
&response_type=code\
&code_challenge=${codeChallenge}\
&code_challenge_method=plain\
&scope=${sso_scope}\
${promptUserLogin ? `&prompt_user_login=1` : ``}\
&state=${state}\
&nonce=${nonce}`;

  return url;
};

const ERROR_LOCALSTORAGE_KEY = 'ERROR';

export const saveErrorToLocalStorage = (
  error: Error,
  pageKey: string,
  apiCallKey: string,
  metaData?: object
) => {
  window.localStorage.setItem(`${ERROR_LOCALSTORAGE_KEY}_NAME`, error.name);
  window.localStorage.setItem(`${ERROR_LOCALSTORAGE_KEY}_MESSAGE`, error.message);

  if (metaData !== undefined) {
    window.localStorage.setItem(`${ERROR_LOCALSTORAGE_KEY}_METADATA`, JSON.stringify(metaData));
  }

  if (error.stack !== undefined) {
    window.localStorage.setItem(`${ERROR_LOCALSTORAGE_KEY}_STACK`, error.stack.toString());
  }

  window.localStorage.setItem(`${ERROR_LOCALSTORAGE_KEY}_APICALLKEY`, apiCallKey);
  window.localStorage.setItem(`${ERROR_LOCALSTORAGE_KEY}_PAGEKEY`, pageKey);
};

export const hasErrorSavedInLocalStorage = () => {
  return window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_NAME`) !== null;
};

export const clearErrorFromLocalStorage = () => {
  window.localStorage.removeItem(`${ERROR_LOCALSTORAGE_KEY}_NAME`);
  window.localStorage.removeItem(`${ERROR_LOCALSTORAGE_KEY}_MESSAGE`);
  window.localStorage.removeItem(`${ERROR_LOCALSTORAGE_KEY}_STACK`);
  window.localStorage.removeItem(`${ERROR_LOCALSTORAGE_KEY}_APICALLKEY`);
  window.localStorage.removeItem(`${ERROR_LOCALSTORAGE_KEY}_PAGEKEY`);
  window.localStorage.removeItem(`${ERROR_LOCALSTORAGE_KEY}_METADATA`);
};

export const getErrorFromLocalStorage = () => {
  const name = window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_NAME`);
  const message = window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_MESSAGE`);
  const stack = window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_STACK`);

  const error = new Error();

  if (typeof name === 'string') {
    error.name = name;
  }

  if (typeof message === 'string') {
    error.message = message;
  }

  if (typeof stack === 'string') {
    error.stack = stack;
  }

  const apiCallKey = window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_APICALLKEY`);
  const pageKey = window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_PAGEKEY`);

  const metaData = window.localStorage.getItem(`${ERROR_LOCALSTORAGE_KEY}_METADATA`);
  const parsedMetaData: object | null = metaData !== null ? JSON.parse(metaData) : null;

  return {
    error,
    apiCallKey,
    pageKey,
    metaData: parsedMetaData,
  };
};

export const redirectToSso = async (promptUserLogin = true) => {
  incrementSSORetries();
  clearPKCEData();
  clearIdToken();
  // We just cleared all PKCE data, generate new data so we can verify the JWT when the user comes back from the SSO.
  getPKCENonce();
  getPKCEState();
  setAuthRedirectUrl();
  const authUrl = await getAuthUrl(promptUserLogin);
  Location.replace(authUrl);
};

export const refreshSSOConfiguration = async () => {
  const { data }: AxiosResponse = await Client.get(
    sso_configuration_endpoint,
    undefined,
    'App',
    'refreshSSOConfiguration'
  );

  const configuration = {
    authorizationEndpoint: data.authorization_endpoint,
    tokenEndpoint: data.token_endpoint,
  };

  saveSSOConfigurationToSessionStorage(configuration);
};

export const saveSSOConfigurationToSessionStorage = (configuration: SSOConfiguration) =>
  window.sessionStorage.setItem(SSO_CONFIGURATION_KEY, JSON.stringify(configuration));

export const getJWTFromAuthenticationCode = async (authenticationCode: string) => {
  const { tokenEndpoint } = await getSSOConfiguration();
  const codeVerifier = getPKCECodeVerifier();

  const { data } = await Client.post(
    tokenEndpoint,
    {
      grant_type: 'authorization_code',
      client_id: sso_client_id,
      code_verifier: codeVerifier,
      code: authenticationCode,
      redirect_uri: getCallBackUrl(),
    },
    undefined,
    'App',
    'getJWTFromAuthenticationCode'
  );

  return data;
};

export const refreshJWT = async (): Promise<JWTResponse | null> => {
  const { tokenEndpoint } = await getSSOConfiguration();

  const { data }: AxiosResponse = await Client.post(
    tokenEndpoint,
    {
      grant_type: 'refresh_token',
      client_id: sso_client_id,
      refresh_token: getRefreshToken(),
    },
    undefined,
    'refresh-jwt',
    'refresh-jwt'
  );

  const { id_token, refresh_token } = data;

  if (!id_token || !refresh_token) {
    await redirectToSso(false);

    return null;
  }

  return {
    id_token,
    refresh_token,
  };
};
