import { useContext, useMemo } from 'react';
import MatchContext from '../contexts/MatchContext';
import { showShootoutsAreRequiredNotification as showShootoutsAreRequiredNotificationService } from '../services/MatchScore';
import {
  matchHasScore as hasScoreService,
  matchHasStarted as hasStartedService,
  isCancelled as isCancelledService,
  isClosed as isClosedService,
  isConfirmedByReferee as isConfirmedByRefereeService,
  isLocked as isLockedService,
  isOpenForSecretary as isOpenForSecretaryService,
  isOpen as isOpenService,
  matchIsDraw as matchIsDrawService,
  numberOfRefereesConfirmed as numberOfRefereesConfirmedService,
} from '../services/match';

/**
 * useMatch
 * Uses the current match to provide easy access to the match-related services.
 *
 * Implemented it this way instead of using the services directly or moving the services directly into the hooks
 * for various reasons: easier to test the stand-alone services (not having to deal with contexts etc),
 * able to use the service for multiple matches, easier to maintain.
 *
 * @example const { isLocked } = useMatch();
 */

export const useMatch = () => {
  const { match } = useContext(MatchContext);

  /*
   * Return true if matched is locked
   */

  const isLocked = useMemo(() => {
    return match && isLockedService(match);
  }, [match]);

  /*
   * Return true if matched is confirmed by referee
   */

  const isConfirmedByReferee = useMemo(() => {
    return match && isConfirmedByRefereeService(match);
  }, [match]);

  /*
   * Return true if match is cancelled
   */

  const isCancelled = useMemo(() => {
    return match && isCancelledService(match);
  }, [match]);

  /*
   * Return number of referees confirmed
   */

  const numberOfRefereesConfirmed = useMemo(() => {
    return match && numberOfRefereesConfirmedService(match);
  }, [match]);

  /*
   * Return true if match is closed
   */

  const isClosed = useMemo(() => {
    return match && isClosedService(match);
  }, [match]);

  /*
   * Return true if match is open
   */

  const isOpen = useMemo(() => {
    return match && isOpenService(match);
  }, [match]);

  /*
   * Return true if match is open for secretary
   */

  const isOpenForSecretary = useMemo(() => {
    return match && isOpenForSecretaryService(match);
  }, [match]);

  /*
   * Return true if match has started
   */

  const hasStarted = useMemo(() => {
    return match && hasStartedService(match);
  }, [match]);

  /*
   * Return true if match has score
   */

  const hasScore = useMemo(() => {
    return hasScoreService(match);
  }, [match]);

  /*
   * Return true if match has score
   */

  const isDraw = useMemo(() => {
    return matchIsDrawService(match);
  }, [match]);

  /*
   * Return true if match has required shootouts
   */

  const showShootoutsAreRequiredNotification = useMemo(() => {
    return match && showShootoutsAreRequiredNotificationService(match);
  }, [match]);

  return {
    isLocked,
    isConfirmedByReferee,
    isCancelled,
    numberOfRefereesConfirmed,
    isOpen,
    isClosed,
    isOpenForSecretary,
    hasStarted,
    hasScore,
    isDraw,
    showShootoutsAreRequiredNotification,
  };
};
