import React from 'react';
import { theme } from '../../templates/ui';

const SubstitutePlayer = ({ className }) => (
  <svg width="24" height="22" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill={theme.color.primary.base} fillRule="nonzero">
      <path d="M0 9.429L3.429 12l1.152-1.344L.813 7.965zM19.42 10.656L20.57 12 24 9.429l-.813-1.464z" />
      <path d="M19.714 1.714L16.235.324C15.982 1.772 14.111 3.43 12 3.43S8.016 1.77 7.765.323l-3.48 1.391L1.233 7.21l3.91 2.793L6 9v12.429h12V9l.86 1.003 3.908-2.793-3.054-5.496z" />
      <path d="M12 2.573c1.893 0 3.429-1.624 3.429-2.571V0H8.572v.002c0 .947 1.535 2.571 3.428 2.571z" />
    </g>
  </svg>
);

export default SubstitutePlayer;
