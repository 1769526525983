import React from 'react';
import { theme } from '../../templates/ui';

const YellowCard = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="card-yellow"
    {...props}
  >
    <rect width="100%" height="100%" fill={theme.color.cards.yellow} />
  </svg>
);

export default YellowCard;
