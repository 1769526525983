import React from 'react';

const Plus = ({ className }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.06 6.932H6.932v5.128H5.189V6.932H.06V5.189h5.13V.06h1.742v5.13h5.128z"
      fillRule="nonzero"
    />
  </svg>
);

export default Plus;
