import React, { useReducer } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { isIOS } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';
import { assignRefereeToMatch, getMatchDetailsForOfficials } from '../../api/matches';
import { ID_TOKEN_KEY, getIdToken, getMatchRoles } from '../../api/sso';
import { withErrorHandler } from '../../contexts/ErrorContext';
import Storage from '../../services/storage';
import { Container } from '../atoms/Container';
import ChevronRight from '../atoms/Icons/ChevronRight';
import Loader from '../atoms/Icons/Loader';
import { Input } from '../atoms/Input';
import { Wrapper } from '../atoms/Wrapper';
import IconButton from '../molecules/IconButton';
import { theme } from '../templates/ui';
import Alert from './Alert';
import TooltipItem from './TooltipItem';

const StyledLabel = styled.label`
  display: flex;
  padding-bottom: ${theme.sizing.large};
  color: ${theme.color.base.base};
  font-size: ${theme.font.large};
`;

const StyledChevron = styled(ChevronRight)`
  fill: ${theme.color.body.base};
`;

const StyledIconButton = styled(IconButton)`
  margin-top: ${theme.sizing.xlarge};
`;

const StyledButtonOk = styled(IconButton)`
  margin-bottom: ${theme.sizing.large};

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    margin-bottom: 0;
    margin-left: ${theme.sizing.large};
  }
`;

const StyledButtonCancel = styled(IconButton)`
  background-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    margin-right: ${theme.sizing.large};
  }

  &:hover,
  &:focus {
    background-color: ${theme.color.gray.alt};
  }
`;

const StyledButtonContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  background-color: ${theme.color.gray.white};
  border-top: 0;
  padding-bottom: 0;
  border-bottom: none;

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
  }
`;

const StyledContainer = styled(Container)`
  background-color: ${theme.color.gray.white};
  border-top: 0;
`;

const StyledLoader = styled(Loader)``;

const StyledAlert = styled(Alert)`
  .container {
    display: flex;
    justify-content: space-around;
    height: auto;
    padding-bottom: ${theme.sizing.large};

    @media only screen and (min-width: ${theme.breakpoints.medium}) {
      padding-bottom: ${theme.sizing.xxxxlarge};
    }

    @media only screen and (min-width: ${theme.breakpoints.large}) {
      max-width: 500px;
    }
  }
`;

const StyledParagraph = styled.p`
  color: ${theme.color.error.base};
`;

const StyledMatchContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const StyledMatchRole = styled.div`
  text-align: center;
  margin-bottom: ${theme.sizing.xxlarge};
`;

const StyledTitle = styled.div`
  text-align: center;
  margin: ${theme.sizing.xxlarge};
`;

const Bold = styled.span`
  font-weight: 700;
  color: ${theme.color.primary.base};
`;

const StyledMatches = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${theme.sizing.xlarge};
`;

const DateContainer = styled.p`
  text-align: center;
  margin: ${theme.sizing.xlarge} 0;
`;

const StyledMatchesCoach = styled.div`
  display: flex;
  justify-content: space-around;
  margin: ${theme.sizing.large};

  span {
    &:nth-child(1) {
      width: 100px;
      text-align: left;
    }

    &:nth-child(3) {
      width: 100px;
      text-align: right;
    }
  }
`;

const StyledTeam = styled.div`
  width: 50%;
  text-align: center;
`;

const StyledTeamCoach = styled.div`
  text-align: center;
`;

const StyledTeamName = styled.div`
  font-weight: 700;
`;

const StyledLogo = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.sizing.small};
  padding: ${theme.sizing.large};
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 2px solid ${theme.color.gray.light};
  background-color: ${theme.color.body.base};
`;

const StyledImage = styled.img`
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

const Divider = styled.div`
  align-self: center;
  height: 3px;
  width: ${theme.sizing.xxlarge};
  background-color: ${theme.color.gray.darker};
`;

const Hr = styled.hr`
  width: calc(100% - ${theme.sizing.large});
  border: 1px solid ${theme.color.gray.light};
  margin: ${theme.sizing.large} ${theme.sizing.small};

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    margin: ${theme.sizing.xlarge} ${theme.sizing.small};
  }
`;

export const ADD_MATCH_BUTTON_TEST_ID = 'add-match-button';

const stateReducer = (state, action) => ({
  ...state,
  ...(typeof action === 'function' ? action(state) : action),
});

const initialState = {
  match_code: '',
  error: false,
  loading: false,
  confirmAlertOpen: false,
  match: null,
  role: null,
  clubLogo: null,
};
const VerifyCode = ({ showGenericError }) => {
  const history = useHistory();
  const invalidMessage =
    'Het format van de ingevulde wedstrijdcode is niet correct. Vul een code in volgens het volgende format: 1234AB';

  const [state, setState] = useReducer(stateReducer, {
    ...initialState,
  });

  const addMatch = async () => {
    const idToken = getIdToken();

    let response;

    try {
      response = await assignRefereeToMatch(
        state.match_code,
        idToken.federation_reference_id,
        'verify-code'
      );
    } catch (error) {
      showGenericError(error, true, true);
    }

    const matchId = response.data.createPersonRole.match.id;
    const matchUuid = response.data.createPersonRole.match.uuid;
    const teamId = response.data.createPersonRole.team.id;

    const refreshedIdToken = await getMatchRoles(
      idToken.federation_reference_id,
      matchId,
      teamId,
      null,
      'verify-code'
    );

    Storage.setItem(ID_TOKEN_KEY, refreshedIdToken.data.id_token);

    setState((prevState) => ({ ...prevState, loading: false }));

    return matchUuid;
  };

  const openConfirmAlert = async (e) => {
    e.preventDefault();

    setState({ loading: true });
    const matchCode = state.match_code;

    getMatchDetailsForOfficials(matchCode, 'verify-code')
      .then(({ data: { match, role } }) => {
        setState((prevState) => ({
          ...prevState,
          match,
          role,
          confirmAlertOpen: true,
        }));
        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((e) => {
        if (!!e.response) {
          setState((prevState) => ({
            ...prevState,
            error: e.response.data.message ? e.response.data.message : e.response.data.error,
          }));

          return;
        }

        setState((prevState) => ({
          ...prevState,
          error: e.toString(),
          loading: false,
        }));
      });
  };

  const confirmOk = async () => {
    setState((prevState) => ({ ...prevState, load: true }));
    let matchUuid;
    try {
      matchUuid = await addMatch();
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        setState((prevState) => ({
          ...prevState,
          confirmAlertOpen: false,
          load: false,
          error: e.response.data.message ? e.response.data.message : e.response.data.error,
        }));

        return;
      }

      setState((prevState) => ({
        ...prevState,
        confirmAlertOpen: false,
        load: false,
        error: 'Er is iets misgegaan. Probeer het later opnieuw.',
      }));

      return;
    }
    history.push(`/matches/${matchUuid}`);
    setState((prevState) => ({
      ...prevState,
      confirmAlertOpen: false,
      load: false,
    }));
  };

  const confirmCancel = () => {
    setState({ confirmAlertOpen: false, match_code: '' });
  };

  const onChange = (e) => {
    const match_code = e.target.value;
    setState((prevState) => ({
      ...prevState,
      match_code,
      error: false,
      loading: false,
    }));
    if (match_code.match(/\d{4}[a-zA-Z]{2}/)) {
      e.target.setCustomValidity('');
    } else {
      e.target.setCustomValidity(invalidMessage);
    }
  };

  moment.locale('nl');
  const matchDate = state.match ? moment(state.match.date, 'YYYY-MM-DD HH:mm') : null;

  return (
    <StyledContainer>
      <StyledAlert showAlert={state.confirmAlertOpen}>
        <StyledMatchContainer>
          {(state.role === 'away_coach_code' || state.role === 'home_coach_code') && (
            <>
              <StyledMatchRole>
                Je bent nu
                <Bold> trainer/coach </Bold>
                van:
              </StyledMatchRole>
              <StyledTeamCoach>
                {state.role === 'home_coach_code' && (
                  <>
                    <StyledLogo>
                      {state.match.home_team.club.logo_picture_url && (
                        <StyledImage src={state.match.home_team.club.logo_picture_url} />
                      )}
                    </StyledLogo>
                    <StyledTeamName>{state.match.home_team.name}</StyledTeamName>
                  </>
                )}
                {state.role === 'away_coach_code' && (
                  <>
                    <StyledLogo>
                      {state.match.away_team.club.logo_picture_url && (
                        <StyledImage src={state.match.away_team.club.logo_picture_url} />
                      )}
                    </StyledLogo>
                    <StyledTeamName>{state.match.away_team.name}</StyledTeamName>
                  </>
                )}
                <StyledTitle>
                  <StyledMatchRole>voor de wedstrijd:</StyledMatchRole>
                </StyledTitle>
                <StyledMatchesCoach>
                  <span>{state.match.home_team.name}</span>
                  <span>vs</span>
                  <span>{state.match.away_team.name}</span>
                </StyledMatchesCoach>
              </StyledTeamCoach>
            </>
          )}
          {state.role === 'match_code' && (
            <>
              <StyledMatchRole>
                Je bent nu <Bold>scheidsrechter</Bold> van de wedstrijd:
              </StyledMatchRole>
              <StyledMatches>
                <StyledTeam>
                  <StyledLogo>
                    {state.match.home_team.club.logo_picture_url && (
                      <StyledImage src={state.match.home_team.club.logo_picture_url} />
                    )}
                  </StyledLogo>
                  <StyledTeamName>{state.match.home_team.name}</StyledTeamName>
                </StyledTeam>
                <Divider />
                <StyledTeam>
                  <StyledLogo>
                    {state.match.away_team.club.logo_picture_url && (
                      <StyledImage src={state.match.away_team.club.logo_picture_url} />
                    )}
                  </StyledLogo>
                  <StyledTeamName>{state.match.away_team.name}</StyledTeamName>
                </StyledTeam>
              </StyledMatches>
            </>
          )}
          {matchDate && (
            <DateContainer>
              Speeldatum: <Bold>{matchDate.format('D MMMM YYYY')}</Bold> om{' '}
              <Bold>{matchDate.format('HH:mm')}</Bold>
            </DateContainer>
          )}
          <Hr />
          <StyledButtonContainer>
            <StyledButtonCancel data-testid="verify-code-cancel" onClick={confirmCancel}>
              Annuleren
            </StyledButtonCancel>
            <StyledButtonOk
              data-testid="verify-code-confirm"
              onClick={confirmOk}
              disabled={state.load}
            >
              Doorgaan
              {state.load ? <StyledLoader /> : <StyledChevron />}
            </StyledButtonOk>
          </StyledButtonContainer>
        </StyledMatchContainer>
      </StyledAlert>
      <Wrapper>
        <StyledLabel>
          Wedstrijdverificatie code invoeren
          <TooltipItem
            containerId="whistle-tooltip"
            tooltipTitle="Wedstrijdcode"
            tooltipBody="Vul hier de code in voor coach of scheidsrechter om de wedstrijd te kunnen openen."
          />
        </StyledLabel>
        <form
          onSubmit={openConfirmAlert}
          onInvalid={(e) => {
            if (isIOS) {
              e.preventDefault();
              alert(invalidMessage);
            }
          }}
        >
          <Input
            placeholder={'Typ verificatie code'}
            onChange={(e) => onChange(e)}
            value={state.match_code}
            pattern="\d{4}[a-zA-Z]{2}"
            required
          />
          {state.error && <StyledParagraph>{state.error}</StyledParagraph>}
          <StyledIconButton
            data-testid={ADD_MATCH_BUTTON_TEST_ID}
            disabled={state.loading || state.match_code === ''}
          >
            Wedstrijd koppelen
            {state.loading && !state.error ? <StyledLoader /> : <StyledChevron />}
          </StyledIconButton>
        </form>
      </Wrapper>
    </StyledContainer>
  );
};

export default withErrorHandler(VerifyCode);
