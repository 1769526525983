import React, { useContext, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { styled } from 'styled-components';
import { PERMISSIONS } from '../../../@types/Permissions';
import { updatePlayerJerseyNumber } from '../../../api/matches';
import { withErrorHandler } from '../../../contexts/ErrorContext';
import { withMatch } from '../../../contexts/MatchContext';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import { getMatchPlayerByClubMemberId } from '../../../services/matchPlayers';
import CardIcons from '../../molecules/CardIcons';
import TooltipItem from '../../molecules/TooltipItem';
import { theme } from '../../templates/ui';
import { AuthorizedCan } from '../AuthorizedCan';
import { Button } from '../Button';
import { renderCards } from '../CardInfoItem';
import Checkmark from '../Icons/Checkmark';
import Cross from '../Icons/Cross';
import Loader from '../Icons/Loader';
import SubstituteIcon from '../Icons/SubstituteIcon';

export const StyledPlayer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${(props) => {
    if (props.disabled) {
      return `${theme.sizing.large} ${theme.sizing.xlarge}`;
    }
    if (props.divergentStyling) {
      return `${theme.sizing.large}`;
    }

    return `${theme.sizing.large} 0`;
  }};
  border-bottom: 1px dashed ${theme.color.gray.light};
  margin: ${({ disabled }) => (disabled ? `0 calc(0px - ${theme.sizing.xlarge})` : '0')};
  background: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, .025);' : 'transparent')};
  background-color: ${(props) =>
    props.divergentStyling ? theme.color.gray.lighter : 'transparent'};

  &:last-of-type {
    border-bottom: none;
  }
`;

const Name = styled.h4`
  margin: 0;
  padding: 6px 0;
  transition: 0.1s all ease-in-out;

  ${({ checked }) =>
    checked &&
    `
    color: ${theme.color.gray.darkest};
  `};
`;

const Number = styled.button`
  display: block;
  padding: 4px 6px 4px 6px;
  margin-right: ${theme.sizing.large};
  background-color: ${theme.color.gray.alt};
  color: black;
  transition: 0.1s all ease-in-out;
  border-width: 0;
  border-radius: 3px;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 30px;
  min-height: 28px;
  text-align: center;

  &:not([disabled]) {
    background-color: ${theme.color.primary.base};
    color: ${theme.color.body.base};

    &:focus,
    &:hover {
      background-color: ${theme.color.primary.hover};
    }
  }
`;

const AddJerseyNumber = styled.div`
  h4 {
    margin: 0;
    padding-bottom: ${theme.sizing.small};
    color: ${theme.color.base.base};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    color: ${theme.color.gray.black};
    text-align: center;
    transition: 0.1s all ease-in-out;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &::before {
      transition: all 0.2s ease-in-out;
      border-radius: 2px;
      left: 0;
      top: -3px;
      width: ${theme.sizing.xxlarge};
      height: ${theme.sizing.xxlarge};
      border: 2px solid lightgrey;
      background-color: transparent;
    }

    &::after {
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 14px;
      opacity: 0;
      left: 3px;
      top: ${theme.sizing.xsmall};
      z-index: 2;
    }
  }

  input[type='checkbox']:checked + label {
    background-color: ${theme.color.secondary.base};
    border: 1px solid ${theme.color.secondary.base};

    svg {
      opacity: 1;
    }
    &::before {
      transition: all 0.2s ease-in-out;
      border: 2px solid ${theme.color.gray.black};
    }
    &::after {
      opacity: 1;
    }
  }
`;

const StyledIconsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: ${theme.sizing.small};
  }
`;

const StyledCheckmark = styled(Checkmark)`
  fill: ${theme.color.gray.white};
`;

const StyledInput = styled.input`
  padding: 14px 36px 14px 14px;
  max-width: 100px;
  border-radius: 3px;
  -webkit-appearance: none;
  border: 1px solid #d3d3d3;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const CheckboxOuter = styled.div`
  position: relative;
  padding-left: ${theme.sizing.xlarge};
  margin-left: auto;
`;

const StyledError = styled.p`
  color: ${theme.color.error.base};
`;

const IconOuter = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${theme.sizing.large};
`;

const StyledClearButton = styled.button`
  position: absolute;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: ${theme.color.gray.darker};
  width: 26px;
  height: 26px;
  right: ${theme.sizing.medium};
  top: 50%;
  cursor: pointer;
  border: none;
  padding: 0;

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    fill: ${theme.color.gray.white};
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
  }

  &:focus,
  &:hover {
    background-color: ${theme.color.gray.darkest};
  }
`;

const PlayerComponent = ({
  jerseyNumber,
  name,
  className,
  checkboxId,
  active,
  clubMemberId,
  onJerseyNumberChange,
  toggleMatchPlayer,
  matchGame,
  matchRefereeConfirmed,
  jerseyNumbersActive,
  disabled,
  substitute,
  cards,
  teamId,
  showGenericError,
}) => {
  const [state, setState] = useState({
    initialJerseyNumber: jerseyNumber,
    errors: false,
    height: 0,
    disabled: false,
    show: false,
  });

  // Optimistic update to UI when toggling player
  const [optimisticActive, setOptimisticActive] = useState(active);

  // Keep optimisticActive in sync when matchGame changes -
  // e.g. when a 422 occurs when toggling a player, the optimistic ui update should be reverted
  // we cannot use active directly because it remains the same when the 422 occurs
  useEffect(() => {
    setOptimisticActive(active);
  }, [matchGame]);

  const { role, hasPermissions } = useContext(RoleContext);

  const toggleJerseyNumberForm = (active) => {
    if (!active) {
      return;
    }

    if (matchRefereeConfirmed && ! hasPermissions([PERMISSIONS.CORRECT_AFTER_CLOSING])) {
      alert('Rugnummers aanpassen is niet meer mogelijk want de wedstrijd is al afgerond.');

      return;
    }

    setState((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  };

  const hasError = (field) => {
    if (!state.errors) {
      return false;
    }

    return !!state.errors[field];
  };

  const getError = (field) => {
    if (!state.errors) {
      return false;
    }

    return state.errors[field][0];
  };

  const hideJerseyNumberForm = () => {
    setState((prevState) => ({ ...prevState, show: false }));
  };

  const updateJerseyNumber = async (clubMemberId, jerseyNumber) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const matchPlayer = getMatchPlayerByClubMemberId(matchGame.match_players, clubMemberId);

    if (!matchPlayer) {
      setState((prevState) => ({ ...prevState, loading: false }));

      return;
    }

    const matchPlayerId = matchPlayer.id;

    try {
      await updatePlayerJerseyNumber(
        matchGame.uuid,
        matchPlayerId,
        jerseyNumber,
        role,
        teamId,
        'match-detail-player'
      );
    } catch (e) {
      showGenericError(e);
    }
    hideJerseyNumberForm();
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const toggleCardInfoPanel = () => {
    const { height } = state;
    setState((prevState) => ({
      ...prevState,
      height: height === 0 ? 'auto' : 0,
    }));
  };

  const canModifyJerseyNumber =
    hasPermissions([PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS], {
      policy: 'some',
      teamId,
    }) && active;

  return (
    <li>
      <StyledPlayer
        className={className}
        disabled={disabled}
        data-testid={`player-${clubMemberId}`}
      >
        {/* Jersey number */}
        <div>
          {jerseyNumbersActive && (
            <Number
              data-testid={`jersey-number-toggle-${clubMemberId}`}
              disabled={!canModifyJerseyNumber}
              onClick={() => toggleJerseyNumberForm(active)}
              canEdit={canModifyJerseyNumber}
            >
              {jerseyNumber}
            </Number>
          )}
        </div>

        <div>
          {/* Player name */}
          <Name checked={active}>{name}</Name>

          {/* Modify jersey number popup */}
          <AuthorizedCan
            has={[PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS]}
            condition={jerseyNumbersActive && active && state.show}
            policy="some"
            teamId={teamId}
          >
            <AddJerseyNumber data-testid={`add-jersey-number-${clubMemberId}`}>
              <h4>Voer een rugnummer in</h4>
              {hasError('jersey_number') && <StyledError>{getError('jersey_number')}</StyledError>}
              <StyledInputWrapper>
                <StyledInput
                  type="number"
                  data-testid={`add-jersey-number-${clubMemberId}-input`}
                  value={jerseyNumber || ''}
                  placeholder="..."
                  disabled={state.loading}
                  onChange={(e) => {
                    onJerseyNumberChange(clubMemberId, e.target.value);
                    if ((e.target.value < 1 || e.target.value > 999) && e.target.value !== '') {
                      setState((prevState) => ({
                        ...prevState,
                        errors: {
                          ...prevState.errors,
                          jersey_number: [
                            'Rugnummers moeten groter zijn dan 1 en kleiner dan 999.',
                          ],
                        },
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        errors: false,
                      }));
                    }
                  }}
                />
                {jerseyNumber && (
                  <StyledClearButton
                    data-testid="delete-button"
                    disabled={state.loading}
                    small={true}
                    onClick={() => {
                      onJerseyNumberChange(clubMemberId, null);
                      updateJerseyNumber(clubMemberId, null);
                    }}
                  >
                    {state.loading ? <Loader /> : <Cross />}
                  </StyledClearButton>
                )}
              </StyledInputWrapper>
              <Button
                data-testid={`add-jersey-number-${clubMemberId}-submit`}
                disabled={
                  state.loading ||
                  (state.errors &&
                    Object.prototype.hasOwnProperty.call(state.errors, 'jersey_number'))
                }
                small={true}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    errors: false,
                  }));
                  updateJerseyNumber(clubMemberId, jerseyNumber);
                }}
              >
                {state.loading ? <Loader /> : 'OK'}
              </Button>
            </AddJerseyNumber>
          </AuthorizedCan>
        </div>

        {/* Icons */}
        <IconOuter>
          <StyledIconsWrapper onClick={toggleCardInfoPanel}>
            <CardIcons cards={cards} isTeamSupport={false} />
            {substitute && (
              <>
                <TooltipItem
                  triggerIcon={<SubstituteIcon />}
                  containerId="substitute-tooltip"
                  tooltipTitle="Invaller"
                  tooltipBody="Als invaller toegevoegd aan dit team."
                />
              </>
            )}
          </StyledIconsWrapper>

          {/* Checkbox to add/remove player to match */}
          <AuthorizedCan
            has={[PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS]}
            condition={
              !matchRefereeConfirmed ||
              (matchRefereeConfirmed && hasPermissions([PERMISSIONS.CORRECT_AFTER_CLOSING]))
            }
            policy="some"
            teamId={teamId}
          >
            <CheckboxOuter data-testid={`player-${clubMemberId}-checkbox`}>
              <CheckboxWrapper>
                <input
                  type="checkbox"
                  id={checkboxId}
                  onChange={async () => {
                    setState((prevState) => ({
                      ...prevState,
                      disabled: true,
                    }));
                    setOptimisticActive(!optimisticActive);
                    await toggleMatchPlayer(clubMemberId);
                    setState((prevState) => ({
                      ...prevState,
                      disabled: false,
                    }));
                  }}
                  checked={optimisticActive || false}
                  disabled={disabled || state.disabled}
                />
                <label htmlFor={checkboxId}>
                  <StyledCheckmark />
                </label>
              </CheckboxWrapper>
            </CheckboxOuter>
          </AuthorizedCan>
        </IconOuter>
      </StyledPlayer>

      {/* Card info collapsible panel */}
      <AnimateHeight duration={500} height={state.height} data-testid={`card-info-${clubMemberId}`}>
        {cards.map((card) => !card.is_coach_card && renderCards(card))}
      </AnimateHeight>
    </li>
  );
};

export const Player = withErrorHandler(withMatch(PlayerComponent));
