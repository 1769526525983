import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import Author from '../Icons/Author';
import Clock from '../Icons/Clock';
import Disobedience from '../Icons/Disobedience';
import Dots from '../Icons/Dots';
import GreenCard from '../Icons/GreenCard';
import Physic from '../Icons/Physic';
import RedCard from '../Icons/RedCard';
import YellowCard from '../Icons/YellowCard';

const StyledInfoItem = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 2px solid ${theme.color.gray.light};
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${theme.sizing.large} 0;
  border-bottom: 1px solid ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};

  &:last-child {
    border: 0;
  }
`;

const Icon = styled.div`
  padding-right: ${theme.sizing.xxlarge};

  svg {
    width: 26px;
    height: 26px;
    fill: #${theme.color.gray.darker};
  }
`;

const CardColor = styled.span`
  display: inline-block;
  margin-right: ${theme.sizing.small};
  color: ${(props) => {
    if (props.cardColor === 'yellow') {
      return theme.color.cards.yellow;
    }
    if (props.cardColor === 'red') {
      return theme.color.cards.red;
    }

    return theme.color.gray.darkest;
  }};
`;

export const CardInfoItem = ({
  card,
  cardColor,
  colorTranslation,
  minutes,
  type,
  description,
  captainCard,
  cardId,
}) => (
  <StyledInfoItem data-testid={'card-info-item-' + cardId}>
    <StyledListItem>
      <Icon cardColor={cardColor}>{card}</Icon>
      <CardColor cardColor={cardColor}>{colorTranslation}</CardColor>
      {captainCard && '- aanvoerderskaart'}
    </StyledListItem>
    <StyledListItem>
      <Icon>
        <Clock />
      </Icon>
      {minutes}&rsquo;
    </StyledListItem>
    <StyledListItem>
      <Icon>
        {type === 'Verbaal' ? <Disobedience /> : type === 'Fysiek' ? <Physic /> : <Dots />}
      </Icon>
      {type}
    </StyledListItem>
    {description && (
      <StyledListItem>
        <Icon>
          <Author />
        </Icon>
        {description}
      </StyledListItem>
    )}
  </StyledInfoItem>
);

export const renderCards = (card) => {
  const cardColorMap = {
    red: {
      icon: <RedCard />,
      translation: 'Rood',
    },
    yellow: {
      icon: <YellowCard />,
      translation: 'Geel',
    },
    default: {
      icon: <GreenCard />,
      translation: 'Groen',
    },
  };

  const cardTypeMap = {
    VERBAL: 'Verbaal',
    PHYSICAL: 'Fysiek',
    default: 'Anders',
  };

  const cardColor = cardColorMap[card.color.toLowerCase()] ?? cardColorMap.default;
  const cardType = cardTypeMap[card.type] ?? cardTypeMap.default;

  return (
    <CardInfoItem
      key={card.id}
      card={cardColor.icon}
      cardColor={card.color}
      colorTranslation={cardColor.translation}
      minutes={card.minute}
      type={cardType}
      description={card.comment}
      captainCard={card.is_captain_card}
      cardId={card.id}
    />
  );
};
