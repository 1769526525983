import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import { CardWrapper } from '../CardWrapper';
import GreenCard from '../Icons/GreenCard';
import RedCard from '../Icons/RedCard';
import YellowCard from '../Icons/YellowCard';
import { TeamRoleIndicator } from '../TeamRoleIndicator';

const StyledGreenCard = styled(GreenCard)`
  width: 26px;
  height: 26px;
`;

const StyledRedCard = styled(RedCard)`
  fill: ${theme.color.cards.red};
  width: 26px;
  height: 26px;
`;

const StyledYellowCard = styled(YellowCard)`
  width: 26px;
  height: 26px;
`;

export const Card = ({ role, color, 'data-testid': dataTestId }) => {
  if (!color) {
    throw new Error(`Color is required.`);
  }

  if (!['green', 'red', 'yellow'].includes(color.toLowerCase())) {
    throw new Error(`${color} is not a valid card color`);
  }

  return (
    <CardWrapper data-testid={dataTestId}>
      {role && <TeamRoleIndicator>{role}</TeamRoleIndicator>}
      {color.toLowerCase() === 'green' && <StyledGreenCard />}
      {color.toLowerCase() === 'red' && <StyledRedCard />}
      {color.toLowerCase() === 'yellow' && <StyledYellowCard />}
    </CardWrapper>
  );
};
