import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import queryString from 'query-string';
import { getAnnouncements } from '../../../api/announcements';
import {
  assignPlayerToMatch,
  show,
  unCancelMatch,
  unassignPlayerFromMatch,
  updateScore,
} from '../../../api/matches';
import { withAppError } from '../../../contexts/AppErrorContext';
import { withErrorHandler } from '../../../contexts/ErrorContext';
import { withMatch } from '../../../contexts/MatchContext';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import { withUser } from '../../../contexts/UserContext';
import {
  getMatchPlayer,
  getTeamById,
  matchHasScore as matchHasScoreService,
  toggleMatchPlayer,
} from '../../../services/match';
import { getActivePlayersByTeamId, keyPlayersByClubMemberId } from '../../../services/matchPlayers';
import { LoadingScreen } from '../../pages/LoadingScreen';
import { MatchDetail } from '../../pages/MatchDetail';

const Match = ({ matchGame, updateMatchGame, user, computedMatch, showGenericError, location }) => {
  const [state, setState] = useState({
    loading: true,
    pushingData: false,
    announcements: [],
  });

  const { role } = useContext(RoleContext);

  const getMatchDetails = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    const queryParams = queryString.parse(location.search);

    try {
      // Request data from endpoint
      const {
        data: { match },
      } = await show(computedMatch.params.id, queryParams.ws, role, 'match-detail');

      // Update cards object (convert casing of card color)
      const parsedMatch = produce(match, (draft) => {
        keyPlayersByClubMemberId(draft);

        draft.match_cards =
          draft.match_cards instanceof Array
            ? draft.match_cards.map((card) => ({
                ...card,
                color: card.color.toLowerCase(),
              }))
            : [];
      });

      // Update match in global state
      updateMatchGame(
        parsedMatch,
        () => setState((prevState) => ({ ...prevState, loading: false })),
        'match-detail'
      );

      // Get announcements for this match
      const { data: announcements } = await getAnnouncements(match.id, 'match-detail');

      // Update announcements in global state
      setState((prevState) => ({ ...prevState, announcements }));
    } catch (error) {
      showGenericError(error, false);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteMatchConfirmation = () => {
    const match = {
      ...matchGame,
      match_approvals: matchGame.match_approvals.filter((approval) => {
        return approval.person.id !== user.id;
      }),
      referee_confirmation: false,
    };

    updateMatchGame(match, null, 'match-detail');
  };

  useEffect(() => {
    const asyncEffect = async function () {
      getMatchDetails();
    };
    asyncEffect();
  }, []);

  // No matchgame available yet or still loading
  if (!matchGame || state.loading) {
    return <LoadingScreen title="Wedstrijd laden" />;
  }

  let pushingScoreDebounce: any = null;
  const debounceTimeout = 1000; // ms, we should make this lower when we improve form performance.

  const handleMatchConfirmation = (personType, matchToUpdate = matchGame) => {
    const currentMatch = matchToUpdate;
    let column;
    switch (personType) {
      case 'home_coach':
        column = 'home_team_approval';
        break;
      case 'away_coach':
        column = 'away_team_approval';
        break;
      case 'referee':
        column = 'referee_confirmation';
        break;
      case 'superuser':
        column = 'superuser_confirmation';
        break;
      default:
        return;
    }

    const match = produce(currentMatch, (draft) => {
      const newApprovals = draft.match_approvals;

      newApprovals.push({
        by_referee: personType === 'referee',
        person: {
          id: user.id,
        },
      });

      draft[column] = true;
      draft.match_approvals = newApprovals;
    });

    updateMatchGame(match, null, 'match-detail');

    return match;
  };

  const handleUpdateScore = (type, score) => {
    if (!matchGame) {
      return;
    }

    const match: any = produce(matchGame, (draft) => {
      score = parseInt(score, 10);
      if (isNaN(score)) {
        score = '';
      } else if (score < 0) {
        score = Math.abs(score);
      }
      draft[`${type}_score`] = score;
    });

    updateMatchGame(
      match,
      () => {
        if (matchHasScoreService(match)) {
          clearTimeout(pushingScoreDebounce);

          setState((prevState) => ({ ...prevState, pushingData: true }));
          pushingScoreDebounce = setTimeout(() => {
            updateScore(
              match.uuid,
              match.home_score,
              match.away_score,
              match.home_shootout_score,
              match.away_shootout_score,
              role,
              'match-detail'
            )
              .catch((error) => {
                showGenericError(error);
              })
              .finally(() =>
                setState((prevState) => ({
                  ...prevState,
                  pushingData: false,
                }))
              );
          }, debounceTimeout);
        }
      },
      'match-detail'
    );
  };

  const handleToggleMatchPlayer = async (clubMemberId, teamId) => {
    let match = matchGame;
    const matchPlayerId = getMatchPlayer(match, clubMemberId);

    if (!matchPlayerId) {
      const foundTeam = getTeamById(match, teamId);
      if (!foundTeam) {
        return;
      }

      const team = foundTeam.team;

      try {
        const {
          data: { id: matchPlayerId },
        } = await assignPlayerToMatch(
          match.uuid,
          teamId,
          clubMemberId,
          team.players[clubMemberId.toString()].jersey_number,
          role,
          'match-detail'
        );
        match = produce(match, (draft) => {
          toggleMatchPlayer(draft, clubMemberId, teamId, matchPlayerId);
        });
      } catch (e) {
        showGenericError(e, true, true);
      }
    } else {
      try {
        if (!matchPlayerId) {
          throw new Error('Match player not found');
        }

        await unassignPlayerFromMatch(match.uuid, matchPlayerId, teamId, role, 'match-detail');
        match = produce(match, (draft) => {
          toggleMatchPlayer(draft, clubMemberId, teamId);
        });
      } catch (e) {
        showGenericError(e, true, true);
      }
    }

    updateMatchGame(match, null, 'match-detail');
  };

  const handleJerseyNumberChange = (clubMemberId, jerseyNumber) => {
    if (jerseyNumber !== null && jerseyNumber < 1 && jerseyNumber !== '') {
      return;
    }

    const match = produce(matchGame, (draft) => {
      draft.match_players[clubMemberId].jersey_number = jerseyNumber;
    });

    updateMatchGame(match, null, 'match-detail');
  };

  const getHomeTeamPlayers = () => {
    return getActivePlayersByTeamId(matchGame, matchGame.home_team.id);
  };

  const getAwayTeamPlayers = () => {
    return getActivePlayersByTeamId(matchGame, matchGame.away_team.id);
  };

  const handleUnCancelMatch = async (matchUuid) => {
    try {
      await unCancelMatch(matchUuid, role, 'match-detail');

      const newMatch = produce(matchGame, (draft) => {
        draft.match_cancellations = [];
      });

      updateMatchGame(
        newMatch,
        () => {
          window.alert('Wedstrijd afgelasting of staking is ongedaan gemaakt');
        },
        'match-detail'
      );
    } catch (e) {
      showGenericError(e);
    }
  };

  return (
    <MatchDetail
      user={user}
      homeTeamPlayers={getHomeTeamPlayers()}
      awayTeamPlayers={getAwayTeamPlayers()}
      match={matchGame}
      handleJerseyNumberChange={handleJerseyNumberChange}
      toggleMatchPlayer={handleToggleMatchPlayer}
      onScoreUpdate={handleUpdateScore}
      routerMatch={computedMatch}
      onMatchConfirmation={handleMatchConfirmation}
      onUncancelMatch={handleUnCancelMatch}
      pushingData={state.pushingData}
      announcements={state.announcements}
      onDeleteMatchConfirmation={handleDeleteMatchConfirmation}
    />
  );
};

export default withErrorHandler(withAppError(withMatch(withUser(Match))));
