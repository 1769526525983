import React from 'react';

const Physic = ({ className }) => (
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g transform="matrix(-1 0 0 1 22 0)">
      <circle transform="rotate(-1.23 13.388 3.22)" cx="13.388" cy="3.219" r="3.085" />
      <path d="M20.095 22.08h-3.917c.188-.831.51-1.904.698-2.602.51-1.851.804-2.844.778-3.488a42.452 42.452 0 0 0-.161-2.12c-.51-6.465-1.234-6.84-2.415-6.84-1.556 0-2.576.133-3.22.777-.268.269-.536.913-.482 1.503.08.59.295 2.227.295 2.227a84.19 84.19 0 0 1-3.059.804 1.391 1.391 0 0 0-1.02 1.69 1.391 1.391 0 0 0 1.69 1.02c1.074-.268 1.986-.483 2.764-.697l.188 1.368c-1.368.107-3.058.241-3.541.322-.886.134-1.744.241-4.32 7.324-.322.859.134 1.825.993 2.147.188.08.375.107.563.107.671 0 1.315-.43 1.583-1.1.725-1.959 1.69-4.293 2.227-5.232.885-.08 2.522-.214 3.837-.322-.966 3.488-1.208 4.75-.483 5.715.348.456.885.724 1.475.724h5.58c.913 0 1.664-.75 1.664-1.663 0-.912-.778-1.664-1.717-1.664zM4.159 13.066l-.94.67c-.16.108-.134.35.054.43l3.086 1.422c.241.107.482-.215.268-.403l-1.368-1.207 1.019-.724a.252.252 0 0 0 0-.403l-2.522-2.039a.21.21 0 0 0-.295 0l-1.046.751c-.135.108-.135.322 0 .403l1.744 1.1zM5.768 16.58l-1.824.108.215-1.234c.026-.161-.108-.295-.269-.295l-3.22.321c-.107 0-.187.108-.214.215l-.215 1.288c-.026.16.135.322.296.268l2.012-.456-.215 1.18c-.027.188.161.323.322.269l3.193-1.18c.268-.108.188-.484-.08-.484z" />
    </g>
  </svg>
);

export default Physic;
